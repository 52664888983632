import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const Info = () => (
  <Layout className='h-auto'>
    <p className='f3 lh-copy'>
      A collective of friends who play music in Brooklyn, NY. We organize shows and periodically release new music.
    </p>
    <p className='f3 lh-solid'>
      <a
        className='f4 f3-ns f2-m f1-l tracked ttu mv4 black no-underline'
        href='mailto:internationalwinnerscollective@gmail.com'
      >
        Contact
      </a>
    </p>
    <p className='f3 lh-solid'>
      <a
        className='f4 f3-ns f2-m f1-l tracked ttu mv4 black no-underline'
        href='https://www.facebook.com/internationalwinnerscollective'
      >
        Facebook
      </a>
    </p>
    <p className='f3 lh-solid'>
      <a
        className='f4 f3-ns f2-m f1-l tracked ttu mv4 black no-underline'
        href='http://soundcloud.com/international-winners'
      >
        SoundCloud
      </a>
    </p>
    <p className='f3 lh-solid'>
      <a
        className='f4 f3-ns f2-m f1-l tracked ttu mv4 black no-underline'
        href='http://blog.intlwinners.com'
      >
        Blog
      </a>
    </p>

    <Link
      className='f5 f4-ns f3-m f2-l tracked ttu mv4 black no-underline'
      to='#'
    >
      Home
    </Link>
  </Layout>
)

export default Info
